import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  transform(value: unknown[], item: unknown): boolean {
    return value.includes(item);
  }
}
