import { Directive, ElementRef, EventEmitter, OnDestroy, Output, Renderer2, inject } from '@angular/core';
import { ColumnModel } from './column.model';

export interface EditableColumnsComponentInterface {
  enterEditableMode(): void;
  enterForcedEditableMode?(): void;
  exitEditableState(): void;
}

export interface ColumnsComponentInterface extends EditableColumnsComponentInterface {
  column: ColumnModel;
  value: any;
  displayNotEditable: boolean;
  // editState: boolean;
  // editStateEntered: EventEmitter<null>;
  // editStateExited: EventEmitter<null>;
  valueChanged: EventEmitter<any>;
  click(): void;

  keydown(event: KeyboardEvent): void;
}

export interface ColumnComponentEventInterface {
  columnName: string;
  componentOutput: any;
}

@Directive()
export class EditableColumnComponent implements EditableColumnsComponentInterface, OnDestroy {
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() editStateEntered: EventEmitter<null> = new EventEmitter();
  @Output() editStateExited: EventEmitter<any> = new EventEmitter();

  private readonly renderer = inject(Renderer2);
  private readonly element = inject(ElementRef);
  private listenerFn = () => {};

  isColumnInEditState!: boolean;
  isForcedEditableMode = false;

  changeColumnValue(value: any) {
    this.valueChanged.emit(value);
    this.exitEditableState();
  }

  enterEditableMode(): void {
    this.isColumnInEditState = true;
    this.editStateEntered.emit();

    if (!this.isForcedEditableMode) {
      this.listenerFn = this.renderer.listen('document', 'click', (event: Event) => {
        if (this.isColumnInEditState && !this.element.nativeElement.contains(event.target)) {
          this.exitEditableState();
        }
      });
    }
  }

  exitEditableState(): void {
    if (!this.isForcedEditableMode) {
      this.listenerFn();
      this.isColumnInEditState = false;
      this.editStateExited.emit();
    }
  }

  enterForcedEditableMode(): void {
    this.isForcedEditableMode = true;
    this.enterEditableMode();
  }

  ngOnDestroy(): void {
    this.exitEditableState();
  }
}
