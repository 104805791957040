"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __spreadArray = this && this.__spreadArray || function (to, from) {
  for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) to[j] = from[i];
  return to;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validate = exports.matchesPositiveInteger = exports.matchesBoolean = exports.makeOptional = exports.matchesString = exports.matchesObject = exports.getErrors = exports.oneOf = exports.matchesArray = exports.matchesEnum = exports.composeSchemas = exports.TYPE_ERROR = void 0;
exports.TYPE_ERROR = 'type_error_expected';
function composeSchemas() {
  var validators = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    validators[_i] = arguments[_i];
  }
  return function (val) {
    var error;
    var i = 0;
    var len = validators.length;
    while (!error && i < len) {
      error = validators[i](val);
      if (error) {
        return error;
      }
      i++;
    }
  };
}
exports.composeSchemas = composeSchemas;
/**
 * Returns a validator that matches values in the given enum
 * @param type - enum to use for match values
 * @public
 */
function matchesEnum(types, options) {
  return function (value) {
    var values = Object.keys(types).map(function (key) {
      return types[key];
    });
    var message = options && options.message || "expected:" + values.map(function (val) {
      return "`" + val + "`";
    }).join(' or ');
    return values.includes(value) ? undefined : constructErrors(value, 'invalid_enum_value', __assign(__assign({}, options), {
      message: message
    }));
  };
}
exports.matchesEnum = matchesEnum;
function matchesArray(validator, options) {
  return function (value) {
    if (!Array.isArray(value)) {
      return constructErrors(value, exports.TYPE_ERROR + "_array", options);
    }
    if (!validator) {
      return;
    }
    var errors = [];
    value.forEach(function (val, key) {
      var objectError = validator(val);
      if (objectError) {
        errors = errors.concat(objectError.map(function (error) {
          return __assign(__assign({}, error), {
            path: "['" + key + "']" + (error.path || '')
          });
        }));
      }
    });
    return errors.length ? errors : undefined;
  };
}
exports.matchesArray = matchesArray;
function oneOf() {
  var validators = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    validators[_i] = arguments[_i];
  }
  return function (val) {
    var errors = [];
    for (var _i = 0, validators_1 = validators; _i < validators_1.length; _i++) {
      var validator = validators_1[_i];
      var result = validator(val);
      if (result == null) return result;
      errors.push.apply(errors, result);
    }
    return errors;
  };
}
exports.oneOf = oneOf;
function constructErrors(value, error, options) {
  if (options === void 0) {
    options = {
      message: undefined
    };
  }
  return [{
    value: value,
    error: error,
    message: typeof options.message === 'function' ? options.message(error, value) : options.message
  }];
}
function getErrors(obj, validator, key) {
  var value = key ? obj[key] : obj;
  var path = key ? "['" + key + "']" : undefined;
  var error = validator(value);
  if (!error) {
    return;
  }
  return error.map(function (errorObj) {
    return __assign(__assign({}, errorObj), {
      path: "" + (path || '') + (errorObj.path || '') || undefined
    });
  });
}
exports.getErrors = getErrors;
function matchesObject(schema, options) {
  return function (val) {
    if (typeof val !== 'object' || !val || Array.isArray(val)) {
      return constructErrors(val, exports.TYPE_ERROR + "_object", options);
    }
    var flattened = Object.keys(schema).reduce(function (acc, key) {
      return __spreadArray(__spreadArray([], acc), getErrors(val, schema[key], key) || []);
    }, []);
    return flattened.length ? flattened : undefined;
  };
}
exports.matchesObject = matchesObject;
function matchesString(options) {
  return function (value) {
    return typeof value === 'string' ? undefined : constructErrors(value, exports.TYPE_ERROR + "_string", options);
  };
}
exports.matchesString = matchesString;
function makeOptional(validator) {
  return function (value) {
    if (value === undefined || value === null) {
      return undefined;
    }
    return validator(value);
  };
}
exports.makeOptional = makeOptional;
function matchesBoolean(options) {
  return function (value) {
    return typeof value === 'boolean' ? undefined : constructErrors(value, exports.TYPE_ERROR + "_boolean", options);
  };
}
exports.matchesBoolean = matchesBoolean;
function matchesPositiveInteger(options) {
  return function (value) {
    return !Number.isInteger(value) || value < 0 ? constructErrors(value, exports.TYPE_ERROR + "_integer", options) : undefined;
  };
}
exports.matchesPositiveInteger = matchesPositiveInteger;
function validate(obj, validator) {
  return getErrors(obj, validator);
}
exports.validate = validate;