import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TaskCompletionButtonComponent } from './task-completion-button/task-completion-button.component';

@NgModule({
  declarations: [],
  imports: [TaskCompletionButtonComponent, CommonModule],
  exports: [TaskCompletionButtonComponent],
})
export class ButtonsModule {}
