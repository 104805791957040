import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../../service/router.service';
import RouterConfig from 'src/app/configs/router.config';
import { ResourcePillData, ResourcePillTypeEnum } from './resource-pill.model';
import { EditableColumnsComponentInterface } from '../../sem-table/table-display/columns-switch/columns/editable-column.component';

@Component({
  selector: 'app-resource-pill',
  templateUrl: './resource-pill.component.html',
  styleUrls: ['./resource-pill.component.scss'],
})
export class ResourcePillComponent implements EditableColumnsComponentInterface, OnInit {
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  edit = false;

  private projectId!: number;
  private defaultTranslateKey: any;
  private _translateKey!: string;
  private route = inject(ActivatedRoute);
  private routerService = inject(RouterService);

  @Input() resources!: ResourcePillData[];
  @Input() type!: ResourcePillTypeEnum;
  @Input() filterTypes: any;
  @Input() set translateKey(key: string) {
    this._translateKey = key;
  }

  get translateKey(): string {
    return this._translateKey || this.defaultTranslateKey;
  }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.params.id;
    console.log(this.projectId);
    console.log(this.resources);
    console.log(this.type);
  }

  enterEditableMode(): void {
    this.edit = true;
  }

  exitEditableState(): void {
    this.edit = false;
  }

  click(resource: ResourcePillData) {
    const storageNameMap: { [key: string]: { storageName: string; filterKey?: string } } = {
      category: { storageName: 'categories', filterKey: 'id' },
      brand: { storageName: 'brands', filterKey: 'id' },
      page: { storageName: 'pages', filterKey: 'id' },
    };
    const columnConfig = storageNameMap[this.type];

    const params = {
      initFiltersParams: {
        storageName: columnConfig.storageName,
        filters: columnConfig.filterKey
          ? {
              [columnConfig.filterKey]: {
                type: this.filterTypes.equal,
                value: resource.id,
              },
            }
          : {},
      },
    };

    switch (this.type) {
      case ResourcePillTypeEnum.CATEGORY:
        this.routerService.navigate(RouterConfig.projectCategories, { project: this.projectId }, params);
        break;
      case ResourcePillTypeEnum.BRAND:
        this.routerService.navigate(RouterConfig.projectBrands, { project: this.projectId }, params);
        break;
      case ResourcePillTypeEnum.PAGE:
        this.routerService.navigate(RouterConfig.projectSitePage, { project: this.projectId, page: resource.id });
        break;
      default:
        console.error('Type not supported');
    }
  }
}
