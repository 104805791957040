"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchesPositiveNumber = exports.TYPE_ERROR = exports.oneOf = exports.matchesArray = exports.validate = exports.matchesPositiveInteger = exports.matchesBoolean = exports.makeOptional = exports.matchesString = exports.matchesObject = exports.matchesEnum = exports.composeSchemas = void 0;
var type_validate_1 = require("@shopify/app-bridge-core/validate/type-validate");
Object.defineProperty(exports, "TYPE_ERROR", {
  enumerable: true,
  get: function () {
    return type_validate_1.TYPE_ERROR;
  }
});
var type_validate_2 = require("@shopify/app-bridge-core/validate/type-validate");
Object.defineProperty(exports, "composeSchemas", {
  enumerable: true,
  get: function () {
    return type_validate_2.composeSchemas;
  }
});
Object.defineProperty(exports, "matchesEnum", {
  enumerable: true,
  get: function () {
    return type_validate_2.matchesEnum;
  }
});
Object.defineProperty(exports, "matchesObject", {
  enumerable: true,
  get: function () {
    return type_validate_2.matchesObject;
  }
});
Object.defineProperty(exports, "matchesString", {
  enumerable: true,
  get: function () {
    return type_validate_2.matchesString;
  }
});
Object.defineProperty(exports, "makeOptional", {
  enumerable: true,
  get: function () {
    return type_validate_2.makeOptional;
  }
});
Object.defineProperty(exports, "matchesBoolean", {
  enumerable: true,
  get: function () {
    return type_validate_2.matchesBoolean;
  }
});
Object.defineProperty(exports, "matchesPositiveInteger", {
  enumerable: true,
  get: function () {
    return type_validate_2.matchesPositiveInteger;
  }
});
Object.defineProperty(exports, "validate", {
  enumerable: true,
  get: function () {
    return type_validate_2.validate;
  }
});
Object.defineProperty(exports, "matchesArray", {
  enumerable: true,
  get: function () {
    return type_validate_2.matchesArray;
  }
});
Object.defineProperty(exports, "oneOf", {
  enumerable: true,
  get: function () {
    return type_validate_2.oneOf;
  }
});
function constructErrors(value, error, options) {
  if (options === void 0) {
    options = {
      message: undefined
    };
  }
  return [{
    value: value,
    error: error,
    message: typeof options.message === 'function' ? options.message(error, value) : options.message
  }];
}
function matchesPositiveNumber(options) {
  return function (value) {
    return Number.isNaN(value) || !Number.isFinite(value) || value < 0 ? constructErrors(value, type_validate_1.TYPE_ERROR + "_number", options) : undefined;
  };
}
exports.matchesPositiveNumber = matchesPositiveNumber;