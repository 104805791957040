"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = exports.validateProps = exports.feedbackModalSchema = void 0;
var FeedbackModal_1 = require("../../actions/FeedbackModal");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return FeedbackModal_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
exports.feedbackModalSchema = type_validate_1.matchesObject({
  formId: type_validate_1.matchesPositiveInteger()
});
function validateProps(props) {
  return type_validate_1.validate(props, exports.feedbackModalSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
  switch (action.type) {
    case FeedbackModal_1.Action.OPEN:
      return type_validate_1.validate(action, utils_1.createActionValidator(FeedbackModal_1.Action, exports.feedbackModalSchema, true));
    case FeedbackModal_1.Action.CLOSE:
    default:
      return type_validate_1.validate(action, utils_1.createActionValidator(FeedbackModal_1.Action));
  }
}
exports.validateAction = validateAction;