import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';

export class ImageUtils {
  private static supportedImageTypes: string[] = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp', 'image/svg+xml'];

  public static isSupportedImage(mimeType: string): boolean {
    return ImageUtils.supportedImageTypes.includes(mimeType);
  }
}

export function minOneItemValidator(control: AbstractControl): ValidationErrors | null {
  const formArray = control as FormArray;
  return formArray && formArray.controls.length > 0 ? null : { minOneItem: true };
}
