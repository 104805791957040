export enum ActionTypesEnum {
  generateValue = 'generateValue',
  override = 'override',
  setEmpty = 'setNull',

  caseChange = 'caseChange',
  capitalized = 'capitalized',
  lower = 'lower',
  upper = 'upper',

  replace = 'replace',
  regexpReplace = 'regexpReplace',
  addEnd = 'addEnd',
  addStart = 'addStart',

  add = 'add',
  divide = 'divide',
  multiply = 'multiply',
  subtract = 'subtract',

  modifyLink = 'param',
  fixUppercase = 'fix_uppercase',
  addFoundFromField = 'firstFindFromWithin',

  multiSearch = 'multi_search',
}
