"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = void 0;
var Cart_1 = require("../../actions/Cart");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Cart_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var addressSchema = type_validate_1.matchesObject({
  address1: type_validate_1.makeOptional(type_validate_1.matchesString()),
  address2: type_validate_1.makeOptional(type_validate_1.matchesString()),
  city: type_validate_1.makeOptional(type_validate_1.matchesString()),
  company: type_validate_1.makeOptional(type_validate_1.matchesString()),
  firstName: type_validate_1.makeOptional(type_validate_1.matchesString()),
  lastName: type_validate_1.makeOptional(type_validate_1.matchesString()),
  phone: type_validate_1.makeOptional(type_validate_1.matchesString()),
  province: type_validate_1.makeOptional(type_validate_1.matchesString()),
  country: type_validate_1.makeOptional(type_validate_1.matchesString()),
  zip: type_validate_1.makeOptional(type_validate_1.matchesString()),
  name: type_validate_1.makeOptional(type_validate_1.matchesString()),
  provinceCode: type_validate_1.makeOptional(type_validate_1.matchesString()),
  countryCode: type_validate_1.makeOptional(type_validate_1.matchesString())
});
var discountSchema = type_validate_1.matchesObject({
  amount: type_validate_1.makeOptional(type_validate_1.matchesPositiveInteger()),
  discountDescription: type_validate_1.makeOptional(type_validate_1.matchesString()),
  type: type_validate_1.makeOptional(type_validate_1.matchesString()),
  discountCode: type_validate_1.makeOptional(type_validate_1.matchesString())
});
var discountAmount = type_validate_1.matchesObject({
  amount: type_validate_1.matchesPositiveNumber(),
  discountDescription: type_validate_1.makeOptional(type_validate_1.matchesString()),
  type: type_validate_1.makeOptional(type_validate_1.matchesString())
});
var discountCode = type_validate_1.matchesObject({
  discountCode: type_validate_1.matchesString()
});
var lineItemSchema = type_validate_1.matchesObject({
  price: type_validate_1.makeOptional(type_validate_1.matchesPositiveNumber()),
  quantity: type_validate_1.makeOptional(type_validate_1.matchesPositiveInteger()),
  title: type_validate_1.makeOptional(type_validate_1.matchesString()),
  variantId: type_validate_1.makeOptional(type_validate_1.matchesPositiveInteger()),
  discount: type_validate_1.makeOptional(discountAmount)
});
var updateLineItemSchema = type_validate_1.matchesObject({
  quantity: type_validate_1.matchesPositiveInteger()
});
var customerSchema = type_validate_1.matchesObject({
  id: type_validate_1.makeOptional(type_validate_1.matchesPositiveInteger()),
  email: type_validate_1.makeOptional(type_validate_1.matchesString()),
  firstName: type_validate_1.makeOptional(type_validate_1.matchesString()),
  lastName: type_validate_1.makeOptional(type_validate_1.matchesString()),
  note: type_validate_1.makeOptional(type_validate_1.matchesString()),
  addresses: type_validate_1.makeOptional(type_validate_1.matchesArray(addressSchema))
});
var noteSchema = type_validate_1.matchesObject({
  name: type_validate_1.matchesString(),
  value: type_validate_1.matchesString()
});
var cartSchema = type_validate_1.matchesObject({
  cartDiscount: type_validate_1.makeOptional(discountSchema),
  customer: type_validate_1.makeOptional(customerSchema),
  grandTotal: type_validate_1.makeOptional(type_validate_1.matchesString()),
  lineItems: type_validate_1.makeOptional(type_validate_1.matchesArray(lineItemSchema)),
  noteAttributes: type_validate_1.makeOptional(type_validate_1.matchesArray(noteSchema)),
  subTotal: type_validate_1.makeOptional(type_validate_1.matchesString()),
  taxTotal: type_validate_1.makeOptional(type_validate_1.matchesString())
});
var propertiesSchema = type_validate_1.composeSchemas(type_validate_1.matchesObject({}), function (value) {
  var validator = type_validate_1.matchesString();
  var schema = Object.keys(value).reduce(function (acc, key) {
    acc[key] = validator;
    return acc;
  }, {});
  return type_validate_1.validate(value, type_validate_1.matchesObject(schema));
});
var matchesStringArray = type_validate_1.matchesArray(type_validate_1.matchesString());
function createDataValidator(data) {
  return utils_1.createActionValidator(Cart_1.Action, data ? type_validate_1.matchesObject({
    data: data
  }) : undefined, true, true);
}
function createDataValidatorWithIndex(data) {
  var indexSchema = type_validate_1.matchesObject({
    index: type_validate_1.matchesPositiveInteger()
  });
  if (data) {
    var dataSchema = type_validate_1.matchesObject({
      data: data
    });
    return utils_1.createActionValidator(Cart_1.Action, type_validate_1.composeSchemas(indexSchema, dataSchema), true, true);
  }
  return utils_1.createActionValidator(Cart_1.Action, indexSchema, true, true);
}
function getDiscountSchema(data) {
  if (data.amount) {
    return discountAmount;
  }
  return discountCode;
}
function validateAction(action) {
  switch (action.type) {
    case Cart_1.Action.UPDATE:
      return type_validate_1.validate(action, createDataValidator(cartSchema));
    case Cart_1.Action.SET_CUSTOMER:
      return type_validate_1.validate(action, createDataValidator(customerSchema));
    case Cart_1.Action.ADD_CUSTOMER_ADDRESS:
      return type_validate_1.validate(action, createDataValidator(addressSchema));
    case Cart_1.Action.UPDATE_CUSTOMER_ADDRESS:
      return type_validate_1.validate(action, createDataValidatorWithIndex(addressSchema));
    case Cart_1.Action.SET_DISCOUNT:
      return type_validate_1.validate(action, createDataValidator(getDiscountSchema(action.payload.data)));
    case Cart_1.Action.SET_PROPERTIES:
      return type_validate_1.validate(action, createDataValidator(propertiesSchema));
    case Cart_1.Action.REMOVE_PROPERTIES:
      return type_validate_1.validate(action, createDataValidator(matchesStringArray));
    case Cart_1.Action.ADD_LINE_ITEM:
      return type_validate_1.validate(action, createDataValidator(lineItemSchema));
    case Cart_1.Action.UPDATE_LINE_ITEM:
      return type_validate_1.validate(action, createDataValidatorWithIndex(updateLineItemSchema));
    case Cart_1.Action.REMOVE_LINE_ITEM:
      return type_validate_1.validate(action, createDataValidatorWithIndex());
    case Cart_1.Action.SET_LINE_ITEM_DISCOUNT:
      return type_validate_1.validate(action, createDataValidatorWithIndex(discountAmount));
    case Cart_1.Action.REMOVE_LINE_ITEM_DISCOUNT:
      return type_validate_1.validate(action, createDataValidatorWithIndex());
    case Cart_1.Action.SET_LINE_ITEM_PROPERTIES:
      return type_validate_1.validate(action, createDataValidatorWithIndex(propertiesSchema));
    case Cart_1.Action.REMOVE_LINE_ITEM_PROPERTIES:
      return type_validate_1.validate(action, createDataValidatorWithIndex(matchesStringArray));
    case Cart_1.Action.FETCH:
    case Cart_1.Action.REMOVE_CUSTOMER:
    case Cart_1.Action.REMOVE_DISCOUNT:
    case Cart_1.Action.CLEAR:
    default:
      return type_validate_1.validate(action, utils_1.createActionValidator(Cart_1.Action, undefined, false, true));
  }
}
exports.validateAction = validateAction;