<app-dialog [dialogRef]="dialogRef">{{ 'general.confirm_action' | translate }}</app-dialog>

<ng-container>
  <mat-dialog-content>
    <p>{{ title | translate: params }}</p>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!isOK; else ok" [cdkTrapFocusAutoCapture]="true" cdkTrapFocus>
    <button (click)="dialogRef.close(false)" class="submit" mat-raised-button type="submit">
      {{ 'general.no' | translate }}
    </button>

    <button (click)="dialogRef.close(true)" cdkFocusInitial class="submit" color="primary" mat-raised-button type="submit">
      {{ 'general.yes' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>

<ng-template #ok>
  <mat-dialog-actions [cdkTrapFocusAutoCapture]="true" cdkTrapFocus>
    <button (click)="dialogRef.close(true)" cdkFocusInitial class="submit" color="primary" mat-raised-button type="submit">
      {{ 'general.ok' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
