import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions, NavigationEnd, NavigationExtras, Params, Router } from '@angular/router';
import { find, forEach } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { filter } from 'rxjs/operators';
import config, { maskChar } from 'src/app/configs/router.config';
import { NotificationService } from 'src/app/notification/notification.service';
import { OperatorEnum } from '../enums/permissions.enum';
import { UrlVariablesType } from '../model/helpers.model';
import { RouteInterface, SuperiorPermissionEnum } from '../model/router-config.model';
import { RouteFiltersGroupParamsInterface } from '../sem-table/filters/models/filter.model';
import { AuthService } from './auth.service';

export { config as routerConfig };

type WindowNameType = '_blank' | '_parent' | '_self' | '_top' | string;

export class NavigateParams {
  hash?: string | null = null;
  initFiltersParams?: RouteFiltersGroupParamsInterface | null = null;
  queryParams?: Params = {};
  skipLocationChange?: boolean = false;
  windowName?: WindowNameType | null = null;
}

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  config = config;
  routerEvents$ = this.router.events && this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
  ) {}

  appHasPermissions(
    permissions: string[],
    operator: OperatorEnum = OperatorEnum.AND,
    superiorPermission: SuperiorPermissionEnum | null = null,
  ): boolean {
    const userPermissions = this.authService.authUser && this.authService.authUser.permissions;
    let appHasPermissions = false;

    if (permissions && userPermissions) {
      const isInclude = (perm: string) => userPermissions.includes(perm);
      const checking = operator === OperatorEnum.OR ? permissions.some(isInclude) : permissions.every(isInclude);

      appHasPermissions = userPermissions && checking;
    }

    // "Frontowe" permissiony (póki co tylko superuser)
    if (superiorPermission) {
      if (superiorPermission === SuperiorPermissionEnum.superuser && !this.authService.authUser!.superuser) {
        appHasPermissions = false;
      }
    }

    return appHasPermissions;
  }

  getCurrentRouteKey() {
    const item: RouteInterface | undefined = find(config, { url: this.router.url });

    return item?.key ?? null;
  }

  // Simulating routerLinkActive and routerLinkActiveOptions with exact
  isRouteActive(routerConfigUrlMask: string, areVariable: boolean): boolean {
    const splitChar = '/';
    let currentRoute = this.router.url;

    if (areVariable && !find(config, { url: currentRoute })) {
      const urlArr = currentRoute.split(splitChar);
      routerConfigUrlMask.split(splitChar).map((val, index) => val === maskChar && (urlArr[index] = maskChar));
      currentRoute = urlArr.join(splitChar);
    }

    return currentRoute === routerConfigUrlMask;
  }

  navigateBlank(route: RouteInterface, queryParams = {}) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route.url], { queryParams })); // TODO: queryParams?
    url && window.open(url, '_blank');
  }

  navigate(
    route: RouteInterface | null = null,
    urlVariables: UrlVariablesType = {},
    params: NavigateParams = new NavigateParams(),
  ): Promise<boolean> | undefined {
    if (route) {
      if (route.permissions && !this.appHasPermissions(route.permissions, route.permissionsOperator || null!)) {
        this.notificationService.warning('no_permission_to_this_resource');
      } else {
        let navigateUrl = route.url;

        if (!isEmpty(urlVariables)) {
          navigateUrl = navigateUrl.prepareUrl(urlVariables);
        }

        const { hash, initFiltersParams, skipLocationChange, queryParams, windowName } = params || {};

        const navigationExtras: NavigationExtras = {};
        hash && (navigationExtras.fragment = hash);
        queryParams && (navigationExtras.queryParams = queryParams);

        if (windowName) {
          if (navigationExtras.queryParams) {
            const query = new URLSearchParams(queryParams).toString();
            query && (navigateUrl = `${navigateUrl}?${query}`);
          }

          navigationExtras.fragment && (navigateUrl = `${navigateUrl}#${navigationExtras.fragment}`);

          const url = this.router.parseUrl(navigateUrl);
          url && window.open(url.toString(), windowName);
        } else {
          initFiltersParams && (navigationExtras.state = { ...navigationExtras.state, ...{ initFiltersParams } });

          if (skipLocationChange) {
            return this.navigateByUrl('/', { skipLocationChange }).then(() => this.router.navigate([navigateUrl], navigationExtras));
          } else {
            return this.router.navigate([navigateUrl], navigationExtras);
          }
        }
      }
    } else {
      console.warn('No route');
    }
  }

  navigateToProject(projectId: number) {
    const projectStates: RouteInterface[] = [
      this.config.projectProducts,
      this.config.projectConnections,
      this.config.projectCampaigns,

      this.config.projectNotFound, // If doesn't have permission to all the previous ones
    ];

    if (projectId) {
      /* eslint-disable consistent-return */
      forEach(projectStates, (route) => {
        const permissions = route.permissions || null; // eslint-disable-line @typescript-eslint/dot-notation

        if (!permissions || this.appHasPermissions(permissions)) {
          this.navigate(route, { project: projectId });
          return false;
        }
      });
    } else {
      this.navigate(this.config.projectNotFound);
    }
  }

  // Remove query params/string from URL
  skipQueryParams() {
    return this.router.navigate([], { queryParams: null, replaceUrl: true });
  }

  navigateByUrl(url: string, options: NavigationBehaviorOptions): Promise<boolean> {
    return this.router.navigateByUrl(url, options);
  }
}
