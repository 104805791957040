<div #input class="container">
  @if (tasks.length) {
    <div class="chip-value-container">
      @for (task of tasks; track task) {
        <div class="chip-value" (click)="click(task); $event.stopPropagation()">
          @if (task.rank_group) {
            <mat-icon class="tw-mr-2">signal_cellular_alt</mat-icon>{{ task.rank_group }}
          } @else {
            {{ 'projects.monitor.results.status.' + task.status | translate }}
          }
          <mat-icon
            class="tw-ml-3 material-symbols-outlined"
            [matTooltip]="'projects.keywords.table.monitor_tooltip' | translate: getTooltipData(task)"
            [matTooltipClass]="'label-tooltip'"
            >info</mat-icon
          >
        </div>
      }
    </div>
  } @else {
    <div class="empty">{{ 'projects.monitor.list.not_monitored' | translate }}</div>
  }
</div>
