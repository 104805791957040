import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export const isPresent = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined;

export const filterList = <T>(list: T[], search: string, key = 'name'): T[] => {
  if (!list) {
    return [];
  }

  if (!search) {
    return list.slice();
  }

  return list.filter((project) => (project[key as keyof typeof project] as string).toLowerCase().indexOf(search.toLowerCase()) > -1);
};

export function passwordStrengthValidator(control: AbstractControl): ValidationErrors | null {
  const { value } = control;
  if (!value) {
    return null;
  }
  const errors: ValidationErrors = {};
  // UpperCase validation
  if (!/[A-Z]/.test(value)) {
    errors.upperCase = true;
  }
  // LowerCase validation
  if (!/[a-z]/.test(value)) {
    errors.lowerCase = true;
  }
  // number validation
  if (!/\d/.test(value)) {
    errors.number = true;
  }
  // special character validation
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    errors.specialChar = true;
  }
  // length validation
  if (Validators.minLength(12)(control)) {
    errors.minlength = true;
  }

  return Object.keys(errors).length ? errors : null;
}

export function positiveNumberValidator(control: AbstractControl): ValidationErrors | null {
  const { value } = control;
  if (typeof value !== 'number' || value <= 0) {
    return { nonPositive: true };
  }
  return null;
}
