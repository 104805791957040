<div *ngIf="bannerVisible" class="limit-wrapper">
  <div class="title-wrapper">
    <mat-icon>warning</mat-icon>
    <h5>{{ 'components.limit_usage.title' | translate }}</h5>
  </div>
  <p>{{ 'components.limit_usage.' + exceedingResources[0] | translate }}</p>
  <button color="secondary" mat-raised-button (click)="navigateToPlan()">
    {{ 'components.limit_usage.upgrade_plan_btn' | translate }}
  </button>
  <mat-icon (click)="closeBanner()" class="close-icon">close</mat-icon>
</div>
