import * as i0 from '@angular/core';
import { EventEmitter, signal, inject, NgZone, PLATFORM_ID, Component, ChangeDetectionStrategy, Input, Output, ViewChild, NgModule } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { asapScheduler } from 'rxjs';
const _c0 = ["chart"];
class ChartComponent {
  constructor() {
    this.autoUpdateSeries = true;
    this.chartReady = new EventEmitter();
    // The instance stored in `signal` will be exposed in the future.
    // Consumers can then use it in conjunction with `computed` to retrieve
    // the latest chart instance and execute logic on the chart whenever it changes.
    this.chartInstance = signal(null);
    this.ngZone = inject(NgZone);
    this.isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  }
  ngOnChanges(changes) {
    if (!this.isBrowser) return;
    this.ngZone.runOutsideAngular(() => {
      asapScheduler.schedule(() => this.hydrate(changes));
    });
  }
  ngOnDestroy() {
    this.destroy();
  }
  hydrate(changes) {
    const shouldUpdateSeries = this.autoUpdateSeries && Object.keys(changes).filter(c => c !== "series").length === 0;
    if (shouldUpdateSeries) {
      this.updateSeries(this.series, true);
      return;
    }
    this.createElement();
  }
  async createElement() {
    const {
      default: ApexCharts
    } = await import('apexcharts');
    window.ApexCharts ||= ApexCharts;
    const options = {};
    if (this.annotations) {
      options.annotations = this.annotations;
    }
    if (this.chart) {
      options.chart = this.chart;
    }
    if (this.colors) {
      options.colors = this.colors;
    }
    if (this.dataLabels) {
      options.dataLabels = this.dataLabels;
    }
    if (this.series) {
      options.series = this.series;
    }
    if (this.stroke) {
      options.stroke = this.stroke;
    }
    if (this.labels) {
      options.labels = this.labels;
    }
    if (this.legend) {
      options.legend = this.legend;
    }
    if (this.fill) {
      options.fill = this.fill;
    }
    if (this.tooltip) {
      options.tooltip = this.tooltip;
    }
    if (this.plotOptions) {
      options.plotOptions = this.plotOptions;
    }
    if (this.responsive) {
      options.responsive = this.responsive;
    }
    if (this.markers) {
      options.markers = this.markers;
    }
    if (this.noData) {
      options.noData = this.noData;
    }
    if (this.xaxis) {
      options.xaxis = this.xaxis;
    }
    if (this.yaxis) {
      options.yaxis = this.yaxis;
    }
    if (this.forecastDataPoints) {
      options.forecastDataPoints = this.forecastDataPoints;
    }
    if (this.grid) {
      options.grid = this.grid;
    }
    if (this.states) {
      options.states = this.states;
    }
    if (this.title) {
      options.title = this.title;
    }
    if (this.subtitle) {
      options.subtitle = this.subtitle;
    }
    if (this.theme) {
      options.theme = this.theme;
    }
    this.destroy();
    const chartInstance = this.ngZone.runOutsideAngular(() => new ApexCharts(this.chartElement.nativeElement, options));
    this.chartInstance.set(chartInstance);
    this.render();
    this.chartReady.emit({
      chartObj: chartInstance
    });
  }
  render() {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.render());
  }
  updateOptions(options, redrawPaths, animate, updateSyncedCharts) {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.updateOptions(options, redrawPaths, animate, updateSyncedCharts));
  }
  updateSeries(newSeries, animate) {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.updateSeries(newSeries, animate));
  }
  appendSeries(newSeries, animate) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.appendSeries(newSeries, animate));
  }
  appendData(newData) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.appendData(newData));
  }
  highlightSeries(seriesName) {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.highlightSeries(seriesName));
  }
  toggleSeries(seriesName) {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.toggleSeries(seriesName));
  }
  showSeries(seriesName) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.showSeries(seriesName));
  }
  hideSeries(seriesName) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.hideSeries(seriesName));
  }
  resetSeries() {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.resetSeries());
  }
  zoomX(min, max) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.zoomX(min, max));
  }
  toggleDataPointSelection(seriesIndex, dataPointIndex) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.toggleDataPointSelection(seriesIndex, dataPointIndex));
  }
  destroy() {
    this.chartInstance()?.destroy();
    this.chartInstance.set(null);
  }
  setLocale(localeName) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.setLocale(localeName));
  }
  paper() {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.paper());
  }
  addXaxisAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.addXaxisAnnotation(options, pushToMemory, context));
  }
  addYaxisAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.addYaxisAnnotation(options, pushToMemory, context));
  }
  addPointAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.addPointAnnotation(options, pushToMemory, context));
  }
  removeAnnotation(id, options) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.removeAnnotation(id, options));
  }
  clearAnnotations(options) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.clearAnnotations(options));
  }
  dataURI(options) {
    return this.chartInstance()?.dataURI(options);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function ChartComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ChartComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ChartComponent,
      selectors: [["apx-chart"]],
      viewQuery: function ChartComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.chartElement = _t.first);
        }
      },
      inputs: {
        chart: "chart",
        annotations: "annotations",
        colors: "colors",
        dataLabels: "dataLabels",
        series: "series",
        stroke: "stroke",
        labels: "labels",
        legend: "legend",
        markers: "markers",
        noData: "noData",
        fill: "fill",
        tooltip: "tooltip",
        plotOptions: "plotOptions",
        responsive: "responsive",
        xaxis: "xaxis",
        yaxis: "yaxis",
        forecastDataPoints: "forecastDataPoints",
        grid: "grid",
        states: "states",
        title: "title",
        subtitle: "subtitle",
        theme: "theme",
        autoUpdateSeries: "autoUpdateSeries"
      },
      outputs: {
        chartReady: "chartReady"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 0,
      consts: [["chart", ""]],
      template: function ChartComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", null, 0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChartComponent, [{
    type: Component,
    args: [{
      selector: "apx-chart",
      template: `<div #chart></div>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true
    }]
  }], null, {
    chart: [{
      type: Input
    }],
    annotations: [{
      type: Input
    }],
    colors: [{
      type: Input
    }],
    dataLabels: [{
      type: Input
    }],
    series: [{
      type: Input
    }],
    stroke: [{
      type: Input
    }],
    labels: [{
      type: Input
    }],
    legend: [{
      type: Input
    }],
    markers: [{
      type: Input
    }],
    noData: [{
      type: Input
    }],
    fill: [{
      type: Input
    }],
    tooltip: [{
      type: Input
    }],
    plotOptions: [{
      type: Input
    }],
    responsive: [{
      type: Input
    }],
    xaxis: [{
      type: Input
    }],
    yaxis: [{
      type: Input
    }],
    forecastDataPoints: [{
      type: Input
    }],
    grid: [{
      type: Input
    }],
    states: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    subtitle: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    autoUpdateSeries: [{
      type: Input
    }],
    chartReady: [{
      type: Output
    }],
    chartElement: [{
      type: ViewChild,
      args: ["chart", {
        static: true
      }]
    }]
  });
})();
const declarations = [ChartComponent];
class NgApexchartsModule {
  /** @nocollapse */static {
    this.ɵfac = function NgApexchartsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgApexchartsModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgApexchartsModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgApexchartsModule, [{
    type: NgModule,
    args: [{
      imports: [declarations],
      exports: [declarations]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-apexcharts
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ChartComponent, NgApexchartsModule };
