"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = exports.validateProps = exports.titleBarSchema = void 0;
var TitleBar_1 = require("../../actions/TitleBar");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return TitleBar_1.Action;
  }
});
var Button_1 = require("../../actions/Button");
var ButtonGroup_1 = require("../../actions/ButtonGroup");
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var button_1 = require("./button");
var buttonGroup_1 = require("./buttonGroup");
var buttonSchemaWithId = type_validate_1.composeSchemas(button_1.buttonSchema, type_validate_1.matchesObject({
  id: type_validate_1.matchesString()
}));
exports.titleBarSchema = type_validate_1.matchesObject({
  breadcrumbs: type_validate_1.makeOptional(button_1.buttonSchema),
  title: type_validate_1.makeOptional(type_validate_1.matchesString()),
  buttons: type_validate_1.makeOptional(type_validate_1.matchesObject({
    primary: type_validate_1.makeOptional(buttonSchemaWithId),
    secondary: type_validate_1.makeOptional(type_validate_1.matchesArray(type_validate_1.composeSchemas(buttonSchemaWithId, type_validate_1.matchesObject({
      buttons: type_validate_1.makeOptional(type_validate_1.matchesArray(buttonSchemaWithId))
    }))))
  }))
});
function validateProps(props) {
  return type_validate_1.validate(props, exports.titleBarSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
  switch (action.type) {
    default:
    case TitleBar_1.Action.UPDATE:
      return type_validate_1.validate(action, utils_1.createActionValidator(TitleBar_1.Action, exports.titleBarSchema, true, false));
    case TitleBar_1.Action.BUTTON_CLICK:
    case TitleBar_1.Action.BREADCRUMBS_CLICK:
      return button_1.validateAction(__assign(__assign({}, action), {
        type: Button_1.Action.CLICK
      }));
    case TitleBar_1.Action.BUTTON_UPDATE:
    case TitleBar_1.Action.BREADCRUMBS_UPDATE:
      return button_1.validateAction(__assign(__assign({}, action), {
        type: Button_1.Action.UPDATE
      }));
    case TitleBar_1.Action.BUTTON_GROUP_UPDATE:
      return buttonGroup_1.validateAction(__assign(__assign({}, action), {
        type: ButtonGroup_1.Action.UPDATE
      }));
  }
}
exports.validateAction = validateAction;