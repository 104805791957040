"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = void 0;
var LeaveConfirmation_1 = require("../../actions/LeaveConfirmation");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return LeaveConfirmation_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
function validateAction(action) {
  var validator = utils_1.createActionValidator(LeaveConfirmation_1.Action);
  return type_validate_1.validate(action, validator);
}
exports.validateAction = validateAction;