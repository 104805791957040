export interface DataNotification {
  task_id: number;
  type?: string;
  priority?: string;
  message_type?: string;
  task_account_id?: number;
}

export interface MessageModel {
  id: number;
  user_id: number;
  message: string;
  created_at: string;
  updated_at: string;
  type: MessageTypeEnum;
  status: number;
  isReaded?: boolean;
  data?: DataNotification;
}

export enum MessageTypeEnum {
  updates = 6,
  info = 5,
  tasks = 4,
  error = 3,
  warning = 2,
  success = 1,
}
