import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

export const cookiesKey = {
  resourceBanner: 'resource_banner_close',
};

@Injectable({
  providedIn: 'root',
})
export class CookiesStorageService {
  private ngxCookieService = inject(CookieService);

  setCookie(cookieKey: string, days?: number) {
    const expiration = days ? days : 30;
    this.ngxCookieService.set(cookieKey, 'true', expiration, '/');
  }

  getCookie(cookieKey: string): string {
    return this.ngxCookieService.get(cookieKey);
  }

  deleteCookie(cookieKey: string) {
    this.ngxCookieService.delete(cookieKey, '/');
  }
}
