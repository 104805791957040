"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mockAppBridge = exports.serverAppBridge = void 0;
var Error_1 = require("../actions/Error");
var noop = function () {};
var noopPromise = new Promise(function () {
  // Noop
});
/**
 * @internal
 */
exports.serverAppBridge = {
  dispatch: function () {
    return {};
  },
  error: function () {
    return noop;
  },
  featuresAvailable: function () {
    return Promise.reject(Error_1.fromAction('Feature detection is only available on the client side.', Error_1.AppActionType.WINDOW_UNDEFINED));
  },
  getState: function () {
    return Promise.reject(Error_1.fromAction('State is only available on the client side.', Error_1.AppActionType.WINDOW_UNDEFINED));
  },
  localOrigin: '',
  hostOrigin: '',
  subscribe: function () {
    return noop;
  }
};
/**
 * @internal
 */
exports.mockAppBridge = {
  dispatch: function () {
    return {};
  },
  error: function () {
    return noop;
  },
  featuresAvailable: function () {
    return noopPromise;
  },
  getState: function () {
    return noopPromise;
  },
  localOrigin: '',
  hostOrigin: '',
  subscribe: function () {
    return noop;
  }
};