import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../modals/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private readonly matDialog = inject(MatDialog);

  // @TODO: podpiąć do każdego wywołania confirm modala - asana: 1200577810065577/1208454530887594
  confirm(key: string): MatDialogRef<ConfirmModalComponent, boolean | string | undefined> | undefined {
    if (this.matDialog.getDialogById(key)) return;

    const config = {
      data: { key },
      id: key,
      maxWidth: '100vw',
      width: '50vw',
    } as MatDialogConfig;

    return this.matDialog.open(ConfirmModalComponent, config);
  }
}
