import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'function', standalone: true })
export class FunctionPipe implements PipeTransform {
  transform<Fn extends (...args: any) => any>(fn: Fn, ...params: Parameters<Fn>): ReturnType<Fn> {
    return fn(...params);
  }
}

@Pipe({ name: 'functionGuard', standalone: true })
export class FunctionGuardPipe implements PipeTransform {
  transform<T>(guardFn: (item: any) => item is T, item: unknown) {
    return guardFn(item);
  }
}
