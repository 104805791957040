export enum ColumnTypesEnum {
  ACTION,
  AUTOCOMPLETE,
  BOOLEAN,
  COMPONENT,
  CUSTOM,
  DATE,
  FILESIZE,
  IMAGE,
  INNER,
  SELECT,
  SIMPLE,
  CHIP_SELECT,
}

export const innerColumnName = 'INNER';

export enum ColumnValueTypesEnum {
  boolean,
  custom,
  date,
  number,
  text,
  url,
  array,
}

export enum ColumnDirectivesEnum {
  none,
  // badge,
  link,
}
