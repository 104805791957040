import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MonitorOrganicTaskModel } from '../../model/monitor.model';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../../service/router.service';
import { RankTypes } from 'src/app/dashboard/project/monitor/monitor.enum';
import RouterConfig from 'src/app/configs/router.config';
import moment from 'moment';
import { EditableColumnsComponentInterface } from '../../sem-table/table-display/columns-switch/columns/editable-column.component';

@Component({
  selector: 'app-monitor-pill',
  templateUrl: './monitor-pill.component.html',
  styleUrls: ['./monitor-pill.component.scss'],
})
export class MonitorPillComponent implements EditableColumnsComponentInterface, OnInit {
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  edit = false;

  private projectId!: number;
  private defaultTranslateKey: any;
  private _translateKey!: string;
  private route = inject(ActivatedRoute);
  private routerService = inject(RouterService);

  @Input() tasks!: MonitorOrganicTaskModel[];
  @Input() filterTypes: any;
  @Input() set translateKey(key: string) {
    this._translateKey = key;
  }

  get translateKey(): string {
    return this._translateKey || this.defaultTranslateKey;
  }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.params.id;
  }

  enterEditableMode(): void {
    this.edit = true;
  }

  exitEditableState(): void {
    this.edit = false;
  }

  click(task: MonitorOrganicTaskModel) {
    const storageNameMap: { [key: string]: { storageName: string; filterKey?: string } } = {
      organic: { storageName: 'monitorOrganicTasks', filterKey: 'id' },
      shopping: { storageName: 'monitorProductAdsTasks', filterKey: 'id' },
      paid: { storageName: 'monitorTextAdsTasks', filterKey: 'id' },
    };
    const columnConfig = storageNameMap[task.type];

    const params = {
      initFiltersParams: {
        storageName: columnConfig.storageName,
        filters: columnConfig.filterKey
          ? {
              [columnConfig.filterKey]: {
                type: this.filterTypes.equal,
                value: task.id,
              },
            }
          : {},
      },
    };

    switch (task.type) {
      case RankTypes.organic:
        this.routerService.navigate(RouterConfig.organicTasks, { project: this.projectId }, params);
        break;
      case RankTypes.shopping:
        this.routerService.navigate(RouterConfig.productAdsTasks, { project: this.projectId }, params);
        break;
      case RankTypes.paid:
        this.routerService.navigate(RouterConfig.textAdsTasks, { project: this.projectId }, params);
        break;
      default:
        console.error('Type not supported');
    }
  }

  getTooltipData(task: MonitorOrganicTaskModel): any {
    return {
      position: task.rank_group ?? '-',
      change_1: task.change_1 ?? '-',
      change_7: task.change_7 ?? '-',
      change_30: task.change_30 ?? '-',

      updated_at: task.updated ? moment(task.updated).format('YYYY-MM-DD HH:mm') : '-',
      status: task.status ?? '-',
      language: task.language ?? '-',
      location: task.location ?? '-',
    };
  }
}
