"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = exports.validateProps = exports.buttonSchemaWithId = exports.buttonSchema = void 0;
var Button_1 = require("../../actions/Button");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Button_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
exports.buttonSchema = type_validate_1.matchesObject({
  disabled: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  label: type_validate_1.matchesString(),
  style: type_validate_1.makeOptional(type_validate_1.matchesEnum(Button_1.Style)),
  icon: type_validate_1.makeOptional(type_validate_1.matchesEnum(Button_1.Icon)),
  loading: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  plain: type_validate_1.makeOptional(type_validate_1.matchesBoolean())
});
exports.buttonSchemaWithId = type_validate_1.composeSchemas(type_validate_1.matchesObject({
  id: type_validate_1.matchesString()
}), exports.buttonSchema);
function validateProps(props) {
  return type_validate_1.validate(props, exports.buttonSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
  var validator = utils_1.createActionValidator(Button_1.Action, action.type === Button_1.Action.UPDATE ? exports.buttonSchema : undefined, true, true);
  return type_validate_1.validate(action, validator);
}
exports.validateAction = validateAction;