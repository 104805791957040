"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateAction = exports.getSectionSchema = exports.matchesAbsolutePath = void 0;
var History = __importStar(require("../../actions/Navigation/History"));
var Redirect = __importStar(require("../../actions/Navigation/Redirect"));
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
function matchesAbsolutePath(value) {
  return value.match('^https?://') ? undefined : [{
    value: value,
    error: 'invalid_absolute_url',
    message: 'expected string to start with `https://` or `http://`'
  }];
}
exports.matchesAbsolutePath = matchesAbsolutePath;
function getSectionSchema(payload) {
  var isProductVariant = payload && payload.section && payload.section.resource && payload.section.name === Redirect.ResourceType.Product;
  var resourceSchema = {
    create: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
    id: type_validate_1.makeOptional(type_validate_1.matchesString())
  };
  var productVariantSchema = __assign(__assign({}, resourceSchema), {
    variant: type_validate_1.makeOptional(type_validate_1.matchesObject(resourceSchema))
  });
  return type_validate_1.matchesObject({
    section: type_validate_1.matchesObject({
      name: type_validate_1.matchesEnum(Redirect.ResourceType),
      resource: type_validate_1.makeOptional(type_validate_1.matchesObject(isProductVariant ? productVariantSchema : resourceSchema))
    })
  });
}
exports.getSectionSchema = getSectionSchema;
function validateAction(action) {
  var newContextSchema = type_validate_1.matchesObject({
    newContext: type_validate_1.makeOptional(type_validate_1.matchesBoolean())
  });
  var actionType = Redirect.Action;
  var schema;
  switch (action.type) {
    case History.Action.PUSH:
    case History.Action.REPLACE:
      actionType = History.Action;
      schema = utils_1.relativePathSchema;
      break;
    case Redirect.Action.APP:
      schema = utils_1.relativePathSchema;
      break;
    case Redirect.Action.REMOTE:
      schema = type_validate_1.composeSchemas(type_validate_1.matchesObject({
        url: type_validate_1.composeSchemas(type_validate_1.matchesString(), function (value) {
          return matchesAbsolutePath(value);
        })
      }), newContextSchema);
      break;
    case Redirect.Action.ADMIN_PATH:
      schema = type_validate_1.composeSchemas(utils_1.relativePathSchema, newContextSchema);
      break;
    case Redirect.Action.ADMIN_SECTION:
      schema = type_validate_1.composeSchemas(getSectionSchema(action.payload), newContextSchema);
      break;
  }
  return type_validate_1.validate(action, utils_1.createActionValidator(actionType, schema));
}
exports.validateAction = validateAction;