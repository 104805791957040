import { Component, Input, effect, signal } from '@angular/core';
import {
  ApexAnnotations,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexForecastDataPoints,
  ApexGrid,
  ApexLegend,
  ApexMarkers,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexStates,
  ApexStroke,
  ApexTheme,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
  ChartType,
} from 'ng-apexcharts';

export type ChartApexOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries; // Data for axis and non-axis charts (e.g., donut)
  chart: ApexChart; // Basic chart configuration
  xaxis?: ApexXAxis; // Configuration for the X-axis (if applicable)
  yaxis?: ApexYAxis | ApexYAxis[]; // Configuration for the Y-axis (if applicable, e.g., in line charts)
  plotOptions?: ApexPlotOptions; // Specific options for bar, pie, or other chart types
  fill?: ApexFill; // Configuration for fill (gradients, colors, etc.)
  tooltip?: ApexTooltip; // Configuration for tooltips
  dataLabels?: ApexDataLabels; // Configuration for data labels displayed on the chart
  labels?: string[]; // Labels for the data
  legend?: ApexLegend; // Configuration for the legend (e.g., position)
  grid?: ApexGrid; // Configuration for the chart grid (guidelines)
  markers?: ApexMarkers; // Configuration for markers in line or scatter charts
  stroke?: ApexStroke; // Configuration for line stroke (e.g., thickness, dash style)
  theme?: ApexTheme; // Color themes (e.g., dark, light)
  annotations?: ApexAnnotations; // Annotations (guidelines, points on the chart)
  responsive?: ApexResponsive[]; // Configuration for different screen widths (e.g., mobile devices)
  states?: ApexStates; // Configuration for chart states (e.g., active, normal, hover)
  forecastDataPoints?: ApexForecastDataPoints; // Forecasted data (if used)
  title?: ApexTitleSubtitle; // Chart title
  subtitle?: ApexTitleSubtitle; // Chart subtitle
  colors?: string[];
};

@Component({
  selector: 'app-apex-chart',
  standalone: true,
  imports: [ChartComponent],
  templateUrl: './apex-chart.component.html',
  styleUrls: ['./apex-chart.component.scss'],
})
export class ApexChartComponent {
  chartConfig = signal<ApexChart | undefined>(undefined);
  protected chartOptionsSignal = signal<Partial<ChartApexOptions>>({});
  private chartTypeSignal = signal<ChartType | undefined>(undefined);

  constructor() {
    effect(
      () => {
        const chartType = this.chartTypeSignal();
        if (chartType && this.chartOptionsSignal().chart) {
          this.chartConfig.set({
            ...this.chartOptionsSignal().chart,
            type: chartType,
          });
        }
      },
      { allowSignalWrites: true },
    );
  }

  @Input({ required: true }) set chartOptions(value: Partial<ChartApexOptions>) {
    this.chartOptionsSignal.set(value);
  }

  @Input({ required: true }) set type(value: ChartType) {
    this.chartTypeSignal.set(value);
  }
}
