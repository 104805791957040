"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateProps = exports.validateAction = exports.toastSchema = void 0;
var Toast_1 = require("../../actions/Toast");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Toast_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
exports.toastSchema = type_validate_1.matchesObject({
  message: type_validate_1.matchesString(),
  duration: type_validate_1.matchesPositiveInteger(),
  isError: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  action: type_validate_1.makeOptional(type_validate_1.matchesObject({
    content: type_validate_1.matchesString()
  }))
});
function validateAction(action) {
  switch (action.type) {
    case Toast_1.Action.SHOW:
      return type_validate_1.validate(action, utils_1.createActionValidator(Toast_1.Action, exports.toastSchema, true));
    default:
      return type_validate_1.validate(action, utils_1.createActionValidator(Toast_1.Action));
  }
}
exports.validateAction = validateAction;
function validateProps(props) {
  return type_validate_1.validate(props, exports.toastSchema);
}
exports.validateProps = validateProps;