import { Directive, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[appIsBetaFeature]',
  standalone: true,
})
export class IsBetaFeatureDirective {
  protected readonly templateRef = inject(TemplateRef);
  protected readonly viewContainer = inject(ViewContainerRef);

  constructor() {
    inject(ActivatedRoute)
      .data.pipe(takeUntilDestroyed())
      .subscribe((data) => {
        if (data?.isBetaFeature) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }
}
