"use strict";

var __spreadArray = this && this.__spreadArray || function (to, from) {
  for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) to[j] = from[i];
  return to;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validatorMiddleware = exports.connectValidatorToDispatchHook = exports.connectValidatorToUpdateHook = void 0;
var client_1 = require("../client");
var types_1 = require("../actions/types");
var Error_1 = require("../actions/Error");
var helper_1 = require("../actions/helper");
var actions_1 = require("./actions");
var type_validate_1 = require("./type-validate");
var utils_1 = require("./utils");
function updateValidator(localOrigin, group, options) {
  switch (group) {
    case types_1.Group.Button:
      return actions_1.Button.validateProps(options);
    case types_1.Group.ButtonGroup:
      return actions_1.ButtonGroup.validateProps(options);
    case types_1.Group.Modal:
      return actions_1.Modal.validateProps(options, localOrigin);
    case types_1.Group.Menu:
      return actions_1.Menu.validateProps(options);
    case types_1.Group.Link:
      return actions_1.Link.validateProps(options);
    case types_1.Group.TitleBar:
      return actions_1.TitleBar.validateProps(options);
    case types_1.Group.ResourcePicker:
      return actions_1.ResourcePicker.validateProps(options);
    case types_1.Group.Toast:
      return actions_1.Toast.validateProps(options);
    case types_1.Group.ContextualSaveBar:
      return actions_1.ContextualSaveBar.validateProps(options);
    case types_1.Group.unstable_Picker:
      return actions_1.unstable_Picker.validateProps(options);
  }
}
function dispatchPayloadValidator(action, localOrigin) {
  switch (action.group) {
    case types_1.Group.Button:
      return actions_1.Button.validateAction(action);
    case types_1.Group.ButtonGroup:
      return actions_1.ButtonGroup.validateAction(action);
    case types_1.Group.Modal:
      return actions_1.Modal.validateAction(action, localOrigin);
    case types_1.Group.Menu:
      return actions_1.Menu.validateAction(action);
    case types_1.Group.TitleBar:
      return actions_1.TitleBar.validateAction(action);
    case types_1.Group.ResourcePicker:
      return actions_1.ResourcePicker.validateAction(action);
    case types_1.Group.Loading:
      return actions_1.Loading.validateAction(action);
    case types_1.Group.Toast:
      return actions_1.Toast.validateAction(action);
    case types_1.Group.Cart:
      return actions_1.Cart.validateAction(action);
    case types_1.Group.Navigation:
      return actions_1.Navigation.validateAction(action);
    case types_1.Group.Print:
      return actions_1.Print.validateAction(action);
    case types_1.Group.Scanner:
      return actions_1.Scanner.validateAction(action);
    case types_1.Group.Fullscreen:
      return actions_1.Fullscreen.validateAction(action);
    case types_1.Group.ContextualSaveBar:
      return actions_1.ContextualSaveBar.validateAction(action);
    case types_1.Group.Link:
      return actions_1.Link.validateAction(action);
    case types_1.Group.unstable_Picker:
      return actions_1.unstable_Picker.validateAction(action);
  }
}
function dispatchValidator(action, origin) {
  var errors = type_validate_1.validate(action, type_validate_1.matchesObject({
    group: type_validate_1.matchesEnum(types_1.Group, {
      message: function (_, value) {
        return "Unknown or unsupported action group `" + value + "`";
      }
    }),
    version: type_validate_1.matchesString()
  }));
  if (errors) {
    return Error_1.invalidAction(action, utils_1.actionMessage(errors));
  }
  var payloadErrors = dispatchPayloadValidator(action, origin);
  return payloadErrors ? Error_1.invalidPayload(action, utils_1.actionMessage(payloadErrors)) : action;
}
var connectValidatorToUpdateHook = function (next) {
  return function (options) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    // The group should be statically set to the default initial group as certain components
    // (ex. Button and ButtonGroup) can be dynamically grouped under a new group (ex. TitleBar)
    // We accept partial options so the validator should be called on merged new and old options
    var mergedOptions = helper_1.getMergedProps(this.options, options);
    var errors = updateValidator(this.app.localOrigin, this.defaultGroup, mergedOptions);
    if (errors) {
      throw Error_1.fromAction(utils_1.actionMessage(errors), Error_1.Action.INVALID_OPTIONS);
    }
    return next.apply(void 0, __spreadArray([options], args));
  };
};
exports.connectValidatorToUpdateHook = connectValidatorToUpdateHook;
var connectValidatorToDispatchHook = function (next) {
  return function (action) {
    var finalAction = dispatchValidator(action, this.localOrigin);
    return next(finalAction);
  };
};
exports.connectValidatorToDispatchHook = connectValidatorToDispatchHook;
var validatorMiddleware = function (hooks) {
  hooks.set(client_1.LifecycleHook.UpdateAction, exports.connectValidatorToUpdateHook);
  hooks.set(client_1.LifecycleHook.DispatchAction, exports.connectValidatorToDispatchHook);
};
exports.validatorMiddleware = validatorMiddleware;