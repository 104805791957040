"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mixedAppClientCheck = exports.actionMessage = exports.relativePathSchema = exports.relativeUrlSchema = exports.isValidRelativePath = exports.createActionValidator = void 0;
var utils_1 = require("@shopify/app-bridge-core/validate/utils");
Object.defineProperty(exports, "createActionValidator", {
  enumerable: true,
  get: function () {
    return utils_1.createActionValidator;
  }
});
Object.defineProperty(exports, "isValidRelativePath", {
  enumerable: true,
  get: function () {
    return utils_1.isValidRelativePath;
  }
});
Object.defineProperty(exports, "relativeUrlSchema", {
  enumerable: true,
  get: function () {
    return utils_1.relativeUrlSchema;
  }
});
Object.defineProperty(exports, "relativePathSchema", {
  enumerable: true,
  get: function () {
    return utils_1.relativePathSchema;
  }
});
function actionMessage(errors) {
  return errors.map(function (err) {
    var path = err.path,
      error = err.error,
      message = err.message,
      value = err.value;
    var valueStr = typeof value === 'object' ? JSON.stringify(value) : value;
    return "`" + error + "` thrown for" + (path ? " path: " + path + " and" : '') + " value: `" + valueStr + "`" + (message ? " with message: " + message : '');
  }).join(' | ');
}
exports.actionMessage = actionMessage;
function mixedAppClientCheck(window) {
  window.addEventListener('DOMContentLoaded', function () {
    if (!Object.prototype.hasOwnProperty.call(window, 'ShopifyApp')) {
      return;
    }
    // eslint-disable-next-line no-console
    console.error("%cException Detected \uD83D\uDEAB\n\n%cAn instance of the EASDK client was detected while initializing Shopify App Bridge. Using Shopify App Bridge and the EASDK simultaneously is not supported.\n\nIf you're migrating an existing app that was built with the shopify_app gem, then the EASDK client might have been included in the home page view template. In this case, remove it from your app before initializing Shopify App Bridge again.", 'font-size: large;', 'font-size: normal;');
  }, {
    once: true
  });
}
exports.mixedAppClientCheck = mixedAppClientCheck;