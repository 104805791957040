"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateAction = exports.validateProps = void 0;
var NavigationMenu_1 = require("../../actions/Menu/NavigationMenu");
var ChannelMenu_1 = require("../../actions/Menu/ChannelMenu");
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var helper_1 = require("../../actions/helper");
var link_1 = require("./link");
var linkOptionsValidator = type_validate_1.matchesObject({
  id: type_validate_1.matchesString(),
  options: link_1.linkPropsSchema
});
function activeLinkError(value) {
  return [{
    error: 'invalid_active_item',
    value: value,
    message: 'expected active item to exist in menu items'
  }];
}
function getOptionsSchema(options) {
  var baseSchema = type_validate_1.matchesObject({
    items: type_validate_1.makeOptional(type_validate_1.matchesArray(linkOptionsValidator)),
    active: type_validate_1.makeOptional(linkOptionsValidator)
  });
  var items = options.items,
    active = options.active;
  if (items && active) {
    var activeItemSchema = type_validate_1.matchesObject({
      active: type_validate_1.composeSchemas(linkOptionsValidator, function (value) {
        return items.find(function (item) {
          return item.id === value.id;
        }) ? undefined : activeLinkError(value);
      })
    });
    return type_validate_1.composeSchemas(baseSchema, activeItemSchema);
  }
  return baseSchema;
}
function getPayloadSchema(payload) {
  var baseSchema = type_validate_1.matchesObject({
    items: type_validate_1.makeOptional(type_validate_1.matchesArray(link_1.linkActionSchema)),
    active: type_validate_1.makeOptional(type_validate_1.matchesString())
  });
  var items = payload.items,
    active = payload.active;
  if (items && active) {
    var activeItemSchema = type_validate_1.matchesObject({
      active: type_validate_1.composeSchemas(type_validate_1.matchesString(), function (value) {
        return items.find(function (item) {
          return item.id === value;
        }) ? undefined : activeLinkError(value);
      })
    });
    return type_validate_1.composeSchemas(baseSchema, activeItemSchema);
  }
  return baseSchema;
}
function validateProps(props) {
  var result = type_validate_1.validate(props, getOptionsSchema(props));
  return result;
}
exports.validateProps = validateProps;
function validateAction(action) {
  var actionType = NavigationMenu_1.Action;
  if (helper_1.findMatchInEnum(ChannelMenu_1.Action, action.type)) {
    actionType = ChannelMenu_1.Action;
  }
  return type_validate_1.validate(action, utils_1.createActionValidator(actionType, getPayloadSchema(action.payload), true, false));
}
exports.validateAction = validateAction;