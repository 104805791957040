import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutoFocusDirective implements OnInit {
  @Input('appAutoFocus') autoFocus!: boolean;

  private readonly el = inject(ElementRef);

  ngOnInit(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;

        if (input) {
          this.el.nativeElement.focus && input.focus();
          // this.el.nativeElement.select && input.select();
          this.el.nativeElement.click && input.click();
        }
      }, 100);
    }
  }
}
