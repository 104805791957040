import { Component, Input, inject } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { AuditStatusEnum } from 'src/app/dashboard/project/audit/audit.enum';
import { AuditModel } from 'src/app/dashboard/project/audit/audit.model';
import { CompetitorsSource, RankTypes } from 'src/app/dashboard/project/monitor/monitor.enum';
import { NotificationService } from 'src/app/notification/notification.service';
import { AdsCompetitorsTypeModel, MatchedProductsInputDataModel, MonitorAuditModel } from '../../model/monitor.model';
import { ChartApexOptions } from '../apex-chart/apex-chart.component';
import { ChartType } from '../../sem-chart/configuration/sem-chart-config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
})
export class AuditComponent {
  auditStatuses = AuditStatusEnum;
  auditUrl!: string;
  isLoaded = false;
  monitorAudit!: MonitorAuditModel;
  organicCompetitors!: { type: RankTypes; data: AdsCompetitorsTypeModel[] };
  paidCompetitors!: { type: RankTypes; data: AdsCompetitorsTypeModel[] };
  restCompetitorsDataSource: MatchedProductsInputDataModel = {};
  shoppingCompetitors!: { type: RankTypes; data: AdsCompetitorsTypeModel[] };
  topCompetitorsDataSource: MatchedProductsInputDataModel = {};

  doughnutTextAdsChartOptions: Partial<ChartApexOptions> = {};
  doughnutShoppingAdsChartOptions: Partial<ChartApexOptions> = {};
  doughnutOrganicChartOptions: Partial<ChartApexOptions> = {};

  private clipboardService = inject(ClipboardService);
  private notificationService = inject(NotificationService);
  private translateService = inject(TranslateService);

  private _audit!: AuditModel;

  get audit(): AuditModel {
    return this._audit;
  }

  @Input() set audit(data: AuditModel) {
    this._audit = data;
    data && this.prepareMonitorAuditResults();
  }

  prepareMonitorAuditResults() {
    if (!this.audit) {
      return false;
    }

    this.auditUrl = `${window.location.origin}/public/audit/${this.audit.project_id}/${this.audit.uuid}`;
    this.monitorAudit = this.audit.monitor_audit || null!;
    this.monitorAudit.product_competitors!.sort((a, b) => b.product_count! - a.product_count! || b.percentage_avg! - a.percentage_avg!);
    this.restCompetitorsDataSource.competitors = this.monitorAudit.product_competitors!.slice(3, 13);
    this.topCompetitorsDataSource.audit = this.restCompetitorsDataSource.audit = this.audit;
    this.topCompetitorsDataSource.competitors = this.monitorAudit.product_competitors!.slice(0, 3);
    this.topCompetitorsDataSource.publicView = this.restCompetitorsDataSource.publicView = this.audit.public;
    this.topCompetitorsDataSource.source = this.restCompetitorsDataSource.source = CompetitorsSource.audit;

    this.organicCompetitors = {
      type: RankTypes.organic,
      data: this.monitorAudit
        .ads_competitors!.map(({ organic }) => organic!)
        .sort((a, b) => b!.matched! - a!.matched!)
        .slice(0, 5),
    };

    this.paidCompetitors = {
      type: RankTypes.paid,
      data: this.monitorAudit
        .ads_competitors!.map(({ paid }) => paid!)
        .sort((a, b) => b!.matched! - a!.matched!)
        .slice(0, 5),
    };

    this.shoppingCompetitors = {
      type: RankTypes.shopping,
      data: this.monitorAudit
        .ads_competitors!.map(({ shopping }) => shopping!)
        .sort((a, b) => b!.matched! - a!.matched!)
        .slice(0, 5),
    };

    const displayedLabel = this.translateService.instant('projects.audit.results.displayed');
    const notDisplayedLabel = this.translateService.instant('projects.audit.results.not_displayed');

    this.doughnutTextAdsChartOptions = {
      series: [
        this.monitorAudit.visible_product_phrases_text!,
        this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_text!,
      ],
      chart: {
        type: ChartType.donut,
        height: 350,
      },
      labels: [displayedLabel, notDisplayedLabel],
      tooltip: {
        enabled: true,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: true,
      },
      fill: {
        colors: ['#BEBEBE', '#D93A31'],
      },
    };

    this.doughnutShoppingAdsChartOptions = {
      series: [
        this.monitorAudit.visible_product_phrases_shopping!,
        this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_shopping!,
      ],
      chart: {
        type: ChartType.donut,
        height: 350,
      },
      labels: [displayedLabel, notDisplayedLabel],
      tooltip: {
        enabled: true,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: true,
      },
      fill: {
        colors: ['#BEBEBE', '#D93A31'],
      },
    };

    this.doughnutOrganicChartOptions = {
      series: [
        this.monitorAudit.visible_product_phrases_ogranic!,
        this.monitorAudit.total_product_phrases! - this.monitorAudit.visible_product_phrases_ogranic!,
      ],
      chart: {
        type: ChartType.donut,
        height: 350,
      },
      labels: [displayedLabel, notDisplayedLabel],
      tooltip: {
        enabled: true,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: true,
      },
      fill: {
        colors: ['#BEBEBE', '#D93A31'],
      },
    };

    this.markLoaded();
  }

  copyLink() {
    this.clipboardService.copy(this.auditUrl);
    this.notificationService.success('link_copied');
  }

  private markLoaded() {
    setTimeout(() => (this.isLoaded = true), 1000);
  }
}
