"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = void 0;
var Fullscreen_1 = require("../../actions/Fullscreen");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Fullscreen_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
/**
 * Performs validation for an fullscreen action
 * @param action the action being dispatched
 * @returns undefined or an array of validation errors
 *
 * @beta
 */
function validateAction(action) {
  var validator = utils_1.createActionValidator(Fullscreen_1.Action);
  return type_validate_1.validate(action, validator);
}
exports.validateAction = validateAction;