"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = exports.validateProps = exports.contextSaveBarSchema = void 0;
var type_validate_1 = require("../type-validate");
var ContextualSaveBar_1 = require("../../actions/ContextualSaveBar");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return ContextualSaveBar_1.Action;
  }
});
var utils_1 = require("../utils");
exports.contextSaveBarSchema = type_validate_1.makeOptional(type_validate_1.matchesObject({
  fullWidth: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  discardAction: type_validate_1.makeOptional(type_validate_1.matchesObject({
    disabled: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
    discardConfirmationModal: type_validate_1.makeOptional(type_validate_1.matchesBoolean())
  })),
  saveAction: type_validate_1.makeOptional(type_validate_1.matchesObject({
    disabled: type_validate_1.makeOptional(type_validate_1.matchesBoolean())
  })),
  leaveConfirmationDisable: type_validate_1.makeOptional(type_validate_1.matchesBoolean())
}));
function validateProps(props) {
  return type_validate_1.validate(props, exports.contextSaveBarSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
  var validator = utils_1.createActionValidator(ContextualSaveBar_1.Action, exports.contextSaveBarSchema);
  return type_validate_1.validate(action, validator);
}
exports.validateAction = validateAction;