import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import apiUrlsConfig from '../../configs/api-urls.config';
import { isPresent } from '../helpers/utils';
import { ResponseV2Interface } from '../model/response.model';

interface GetConfig {
  accountId?: number;
  projectId?: number;
  name: string;
}

interface SaveConfig<T> extends GetConfig {
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class CustomSettingsService {
  private readonly http = inject(HttpClient);

  get<T>(config: GetConfig) {
    let params = new HttpParams().set('name', config.name);
    if (isPresent(config.projectId)) {
      params = params.set('project_id', config.projectId);
    }
    if (isPresent(config.accountId)) {
      params = params.set('account_id', config.accountId);
    }

    return this.http.get<ResponseV2Interface<{ data?: T }>>(apiUrlsConfig.settingsGetStorage, { params });
  }

  save<T>(config: SaveConfig<T>) {
    return this.http.post<void>(apiUrlsConfig.settingsSetStorage, {
      data: config.data,
      name: config.name,
      project_id: config.projectId,
      account_id: config.accountId,
    });
  }
}
