import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TaskStatusEnum } from 'src/app/dashboard/tasks/tasks.enum';
import { TaskInterface } from 'src/app/dashboard/tasks/tasks.model';

export type TaskCompletionType = { data: TaskInterface; type: 'mark_task_completed' | 'reopen_task' };

@Component({
  selector: 'app-task-completion-button',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './task-completion-button.component.html',
  styleUrl: './task-completion-button.component.scss',
})
export class TaskCompletionButtonComponent {
  @Input() data: TaskInterface | null = null;
  @Output() changed: EventEmitter<TaskCompletionType> = new EventEmitter();

  taskStatusEnum = TaskStatusEnum;

  changeCompletionStatus() {
    const type = this.data?.status === TaskStatusEnum.finished ? 'reopen_task' : 'mark_task_completed';

    this.changed.emit({ data: this.data!, type });
  }
}
