"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = exports.validateProps = exports.linkActionSchema = exports.linkPropsSchema = void 0;
var AppLink_1 = require("../../actions/Link/AppLink");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return AppLink_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var AllowedRedirectType;
(function (AllowedRedirectType) {
  AllowedRedirectType["APP"] = "APP::NAVIGATION::REDIRECT::APP";
  AllowedRedirectType["LEGACY_APP"] = "APP";
})(AllowedRedirectType || (AllowedRedirectType = {}));
exports.linkPropsSchema = type_validate_1.matchesObject({
  label: type_validate_1.matchesString(),
  destination: utils_1.relativeUrlSchema,
  redirectType: type_validate_1.matchesEnum(AllowedRedirectType)
});
exports.linkActionSchema = type_validate_1.matchesObject({
  label: type_validate_1.matchesString(),
  destination: utils_1.relativePathSchema,
  redirectType: type_validate_1.matchesEnum(AllowedRedirectType)
});
function validateProps(props) {
  return type_validate_1.validate(props, exports.linkPropsSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
  return type_validate_1.validate(action, utils_1.createActionValidator(AppLink_1.Action, exports.linkActionSchema, true, true));
}
exports.validateAction = validateAction;