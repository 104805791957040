import { Injectable } from '@angular/core';
import { ColumnModel } from '../table-display/columns-switch/columns/column.model';
import { FilterLogicOperatorEnum } from './FilterLogicOperatorEnum';
import { FilterTypesEnum } from './FilterTypesEnum';
import { EndFilterModel } from './models/EndFilter.model';
import { EqualFilterModel } from './models/EqualFilter.model';
import { ExcludeFilterModel } from './models/ExcludeFilter.model';
import { ExistsInFilterModel } from './models/ExistsInFilter.model';
import { FieldNotIncludeFieldFilterModel } from './models/FieldNotIncludeFieldFilter.model';
import { IncludeFilterModel } from './models/IncludeFilter.model';
import { IncludeOneOfFilterModel } from './models/IncludeOneOfFilter.model';
import { IsEmptyFilterModel } from './models/IsEmptyFilter.model';
import { IsUppercaseFilterModel } from './models/IsUppercaseFilter.model';
import { NotEmptyFilterModel } from './models/NotEmptyFilter.model';
import { NotExistsInFilterModel } from './models/NotExistsInFilter.model';
import { NumberBiggerFilterModel } from './models/NumberBiggerFilter.model';
import { NumberBiggerOrEqualFilterModel } from './models/NumberBiggerOrEqualFilter.model';
import { NumberEqualFilterModel } from './models/NumberEqualFilter.model';
import { NumberSmallerEqualFilterModel } from './models/NumberSmallerEqualFilter.model';
import { NumberSmallerFilterModel } from './models/NumberSmallerFilter.model';
import { RegexpFilterModel } from './models/RegexpFilter.model';
import { StartFilterModel } from './models/StartFilter.model';
import { StringBiggerFilterModel } from './models/StringBiggerFilter.model';
import { StringSmallerFilterModel } from './models/StringSmallerFilter.model';
import { FilterModel } from './models/filter.model';
import { MultiSearchFilterModel } from './models/MultiSearchFilter.model';

@Injectable({ providedIn: 'root' })
export class FilterFactoryService {
  createFilter(
    column: ColumnModel,
    filterType: FilterTypesEnum,
    initValue: any,
    operator: FilterLogicOperatorEnum,
    caseSensitive?: boolean,
    additionalSources?: any,
    project_connection_id?: number,
    start_date?: string,
    end_date?: string,
  ): FilterModel {
    const { baseColumnName, param, type } = column;
    const columnName = baseColumnName || param;

    switch (filterType) {
      case FilterTypesEnum.is_empty:
        return new IsEmptyFilterModel(
          columnName,
          initValue,
          type,
          operator,
          caseSensitive,
          additionalSources,
          project_connection_id,
          start_date,
          end_date,
        );
      case FilterTypesEnum.not_empty:
        return new NotEmptyFilterModel(
          columnName,
          initValue,
          type,
          operator,
          caseSensitive,
          additionalSources,
          project_connection_id,
          start_date,
          end_date,
        );
      case FilterTypesEnum.is_uppercase:
        return new IsUppercaseFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.end:
        return new EndFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.start:
        return new StartFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.equal:
        return new EqualFilterModel(
          columnName,
          initValue,
          type,
          operator,
          caseSensitive,
          additionalSources,
          project_connection_id,
          start_date,
          end_date,
        );
      case FilterTypesEnum.regexp:
        return new RegexpFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.exists_in:
        return new ExistsInFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      //TODO change model ExistsInFilterModel
      case FilterTypesEnum.in_array:
        return new MultiSearchFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.not_exists_in:
        return new NotExistsInFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.num_equal:
        return new NumberEqualFilterModel(
          columnName,
          initValue,
          type,
          operator,
          caseSensitive,
          additionalSources,
          project_connection_id,
          start_date,
          end_date,
        );
      case FilterTypesEnum.num_bigger:
        return new NumberBiggerFilterModel(
          columnName,
          initValue,
          type,
          operator,
          caseSensitive,
          additionalSources,
          project_connection_id,
          start_date,
          end_date,
        );
      case FilterTypesEnum.string_bigger:
        return new StringBiggerFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.num_biggerrEqual:
        return new NumberBiggerOrEqualFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.num_smaller:
        return new NumberSmallerFilterModel(
          columnName,
          initValue,
          type,
          operator,
          caseSensitive,
          additionalSources,
          project_connection_id,
          start_date,
          end_date,
        );
      case FilterTypesEnum.string_smaller:
        return new StringSmallerFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.num_smallerEqual:
        return new NumberSmallerEqualFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.field_not_include_field:
        return new FieldNotIncludeFieldFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.include:
        return new IncludeFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.include_one_of:
        return new IncludeOneOfFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);
      case FilterTypesEnum.exclude:
        return new ExcludeFilterModel(columnName, initValue, type, operator, caseSensitive, additionalSources);

      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const exhaustCheck: never = filterType;
        return null!;
      }
    }
  }
}
