export enum ResourcePillTypeEnum {
  CATEGORY = 'category',
  BRAND = 'brand',
  PAGE = 'page',
}

export interface ResourcePillData {
  id: number;
  value: string;
}
