"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = exports.validateProps = void 0;
var ResourcePicker_1 = require("../../actions/ResourcePicker");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return ResourcePicker_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var resourceSelectionSchema = type_validate_1.matchesArray(type_validate_1.matchesObject({
  id: type_validate_1.matchesString()
}));
var resourcePickerOptions = type_validate_1.matchesObject({
  initialQuery: type_validate_1.makeOptional(type_validate_1.matchesString()),
  filterQuery: type_validate_1.makeOptional(type_validate_1.matchesString()),
  initialSelectionIds: type_validate_1.makeOptional(resourceSelectionSchema),
  selectMultiple: type_validate_1.makeOptional(type_validate_1.oneOf(type_validate_1.matchesBoolean(), type_validate_1.matchesPositiveInteger())),
  showHidden: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  showVariants: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  showDraft: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  showArchived: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  showDraftBadge: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  showArchivedBadge: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  actionVerb: type_validate_1.makeOptional(type_validate_1.matchesEnum(ResourcePicker_1.ActionVerb))
});
var resourcePickerActionSchema = type_validate_1.matchesObject({
  resourceType: type_validate_1.matchesEnum(ResourcePicker_1.ResourceType),
  options: type_validate_1.makeOptional(resourcePickerOptions)
});
var selectionSchema = type_validate_1.matchesObject({
  selection: resourceSelectionSchema
});
function validateProps(props) {
  return type_validate_1.validate(props, resourcePickerOptions);
}
exports.validateProps = validateProps;
function validateAction(action) {
  switch (action.type) {
    case ResourcePicker_1.Action.UPDATE:
    case ResourcePicker_1.Action.OPEN:
      return type_validate_1.validate(action, utils_1.createActionValidator(ResourcePicker_1.Action, resourcePickerActionSchema, false, true));
    case ResourcePicker_1.Action.SELECT:
      return type_validate_1.validate(action, utils_1.createActionValidator(ResourcePicker_1.Action, selectionSchema, true, true));
    case ResourcePicker_1.Action.CANCEL:
    case ResourcePicker_1.Action.CLOSE:
    default:
      return type_validate_1.validate(action, utils_1.createActionValidator(ResourcePicker_1.Action));
  }
}
exports.validateAction = validateAction;