"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = exports.validateProps = void 0;
var Picker_1 = require("../../actions/Picker");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Picker_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var resourceBadge = type_validate_1.matchesObject({
  content: type_validate_1.matchesString(),
  id: type_validate_1.matchesString(),
  progress: type_validate_1.matchesEnum(Picker_1.ALL_BADGE_PROGRESSES),
  status: type_validate_1.matchesEnum(Picker_1.ALL_BADGE_STATUSES)
});
var resourceMedia = type_validate_1.matchesObject({
  accessibilityLabel: type_validate_1.makeOptional(type_validate_1.matchesString()),
  alt: type_validate_1.makeOptional(type_validate_1.matchesString()),
  initials: type_validate_1.makeOptional(type_validate_1.matchesString()),
  kind: type_validate_1.makeOptional(type_validate_1.matchesEnum(Picker_1.ALL_MEDIA_KINDS)),
  name: type_validate_1.makeOptional(type_validate_1.matchesString()),
  source: type_validate_1.matchesString()
});
var sharedResourceSchema = {
  accessibilityLabel: type_validate_1.makeOptional(type_validate_1.matchesString()),
  badges: type_validate_1.makeOptional(type_validate_1.matchesArray(resourceBadge)),
  disabled: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  id: type_validate_1.matchesString(),
  loading: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  media: type_validate_1.makeOptional(resourceMedia),
  name: type_validate_1.makeOptional(type_validate_1.matchesString()),
  caption: type_validate_1.makeOptional(type_validate_1.matchesString()),
  selectable: type_validate_1.makeOptional(type_validate_1.matchesBoolean())
};
var resourceOption = type_validate_1.matchesObject(__assign({}, sharedResourceSchema));
var resourceSelectionSchema = type_validate_1.matchesArray(type_validate_1.matchesObject(__assign(__assign({}, sharedResourceSchema), {
  options: type_validate_1.makeOptional(type_validate_1.matchesArray(resourceOption))
})));
var resourceName = type_validate_1.matchesObject({
  plural: type_validate_1.matchesString(),
  singular: type_validate_1.matchesString()
});
var pickerOptions = type_validate_1.matchesObject({
  canLoadMore: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  emptySearchLabel: type_validate_1.makeOptional(type_validate_1.matchesObject({
    title: type_validate_1.matchesString(),
    description: type_validate_1.matchesString(),
    withIllustration: type_validate_1.matchesBoolean()
  })),
  items: type_validate_1.makeOptional(resourceSelectionSchema),
  loading: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  loadingMore: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  maxSelectable: type_validate_1.makeOptional(type_validate_1.matchesPositiveInteger()),
  primaryActionLabel: type_validate_1.makeOptional(type_validate_1.matchesString()),
  searchQuery: type_validate_1.makeOptional(type_validate_1.matchesString()),
  searchQueryPlaceholder: type_validate_1.makeOptional(type_validate_1.matchesString()),
  secondaryActionLabel: type_validate_1.makeOptional(type_validate_1.matchesString()),
  selectedItems: type_validate_1.makeOptional(type_validate_1.matchesArray(type_validate_1.matchesObject(sharedResourceSchema))),
  title: type_validate_1.makeOptional(type_validate_1.matchesString()),
  verticalAlignment: type_validate_1.makeOptional(type_validate_1.matchesEnum(Picker_1.ALL_RESOURCE_VERTICAL_ALIGNMENT)),
  allowEmptySelection: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
  resourceName: type_validate_1.makeOptional(resourceName)
});
var pickerActionSchema = type_validate_1.matchesObject({
  options: type_validate_1.makeOptional(resourceSelectionSchema)
});
var selectionSchema = type_validate_1.matchesObject({
  selectedItems: resourceSelectionSchema
});
var searchSchema = type_validate_1.matchesObject({
  searchQuery: type_validate_1.makeOptional(type_validate_1.matchesString())
});
/**
 * @unstable This API may be updated without warning in the future
 */
function validateProps(props) {
  return type_validate_1.validate(props, pickerOptions);
}
exports.validateProps = validateProps;
/**
 * @unstable This API may be updated without warning in the future
 */
function validateAction(action) {
  switch (action.type) {
    case Picker_1.Action.UPDATE:
    case Picker_1.Action.OPEN:
      return type_validate_1.validate(action, utils_1.createActionValidator(Picker_1.Action, pickerActionSchema, false, true));
    case Picker_1.Action.SELECT:
      return type_validate_1.validate(action, utils_1.createActionValidator(Picker_1.Action, selectionSchema, true, true));
    case Picker_1.Action.SEARCH:
      return type_validate_1.validate(action, utils_1.createActionValidator(Picker_1.Action, searchSchema, true, true));
    case Picker_1.Action.CANCEL:
    case Picker_1.Action.LOAD_MORE:
      return type_validate_1.validate(action, utils_1.createActionValidator(Picker_1.Action));
    default:
      return type_validate_1.validate(action, utils_1.createActionValidator(Picker_1.Action));
  }
}
exports.validateAction = validateAction;