<apx-chart
  [annotations]="chartOptionsSignal().annotations"
  [chart]="chartConfig()"
  [dataLabels]="chartOptionsSignal().dataLabels"
  [fill]="chartOptionsSignal().fill"
  [grid]="chartOptionsSignal().grid"
  [labels]="chartOptionsSignal().labels"
  [legend]="chartOptionsSignal().legend"
  [markers]="chartOptionsSignal().markers"
  [plotOptions]="chartOptionsSignal().plotOptions"
  [responsive]="chartOptionsSignal().responsive"
  [series]="chartOptionsSignal().series"
  [states]="chartOptionsSignal().states"
  [stroke]="chartOptionsSignal().stroke"
  [theme]="chartOptionsSignal().theme"
  [tooltip]="chartOptionsSignal().tooltip"
  [xaxis]="chartOptionsSignal().xaxis"
  [yaxis]="chartOptionsSignal().yaxis"
></apx-chart>
