import { MatDialogConfig } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ActionTypesEnum } from '../../../../action/ActionTypesEnum';
import { filterTypesGroups, mainTableColumnDefaultGroup } from '../../../../enums';
import { FilterTypesEnum } from '../../../../filters/FilterTypesEnum';
import { ItemModel } from '../../../../item.model';
import { CustomButtonPerItemInterface } from '../../../../models/CustomButton.model';
import { ToolInterface } from '../../../../models/TableConfigurationInterface.model';
import { AgregationInterface } from '../../../../models/agregation.model';
import { ColumnDirectivesEnum, ColumnTypesEnum, ColumnValueTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnComponentModel, ColumnModel } from '../column.model';

export class SimpleColumn extends ColumnModel {
  lettersLimit: number;
  stringTruncateLimit: number;
  textAreaAlways?: boolean;
  type = ColumnTypesEnum.SIMPLE;

  constructor(config: {
    ableToFilter?: boolean;
    actionsAvailable?: ActionTypesEnum[];
    aggregate?: boolean;
    aggregateBySelect?: AgregationInterface;
    alias: string;
    canAiGenerated?: boolean;
    canBeSorted?: boolean;
    canCopy?: boolean;
    component?: ColumnComponentModel;
    customButton?: CustomButtonPerItemInterface[];
    dialog?: { config: MatDialogConfig; component: any };
    directive?: ColumnDirectivesEnum;
    disableDirectFilter?: boolean;
    displayFullLength?: boolean;
    editable?: ToolInterface;
    filtersAvailable?: FilterTypesEnum[];
    group?: string;
    grouping?: boolean;
    lettersLimit?: number;
    required?: boolean;
    responseMapping?: any;
    stringTruncateLimit?: number;
    textAreaAlways?: boolean;
    tooltip?: string;
    valueType: ColumnValueTypesEnum;
    disabledIn?: {
      duplicatePopup?: boolean;
      editPopup?: boolean;
      addPopup?: boolean;
      usingAsFilterInMassOperations?: boolean;
    };
  }) {
    super();
    this.ableToFilter = config.ableToFilter;
    this.actionsAvailableTypes = config.actionsAvailable ? config.actionsAvailable : this.actionsAvailableTypes;
    this.aggregate = config.aggregate;
    this.aggregateBySelect = config.aggregateBySelect;
    this.alias = config.alias;
    this.canAiGenerated = config.canAiGenerated;
    this.canBeSorted = config.canBeSorted;
    this.canCopy = config.canCopy;
    this.customButton = config.customButton;
    this.dialog = config.dialog;
    this.directive = config.directive || null!;
    this.disabledIn = config.disabledIn;
    this.disableDirectFilter = config.disableDirectFilter;
    this.displayFullLength = config.displayFullLength;
    this.editable = config.editable;
    this.group = config.group || mainTableColumnDefaultGroup;
    this.grouping = config.grouping;
    this.required = config.required!;
    this.responseMapping = config.responseMapping!;
    this.textAreaAlways = config.textAreaAlways;
    this.tooltip = config.tooltip;
    this.valueType = config.valueType;

    const f = filterTypesGroups;
    const filtersDefaultFromValueType = this.valueType === ColumnValueTypesEnum.number ? f.allFiltersNumeric : f.allFilters;
    this.filterAvailableTypes = config.filtersAvailable ? config.filtersAvailable : filtersDefaultFromValueType;

    this.lettersLimit = config.lettersLimit!;
    this.stringTruncateLimit = config.stringTruncateLimit!;

    config.component && (this.component = config.component);
  }

  returnValue(item: ItemModel): any {
    const res = item[this.param as keyof typeof item];
    return res;
  }

  returnPossibleValues(): Observable<any> {
    return of([]);
  }

  returnValueForDisplay(item: ItemModel): string[] {
    const res = item[this.param as keyof typeof item] as any[];
    return res.map((value) => this.map(value));
  }
}
