"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = exports.validateAction = void 0;
var Scanner_1 = require("../../actions/Scanner");
Object.defineProperty(exports, "Action", {
  enumerable: true,
  get: function () {
    return Scanner_1.Action;
  }
});
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
function validateAction(action) {
  return type_validate_1.validate(action, utils_1.createActionValidator(Scanner_1.Action));
}
exports.validateAction = validateAction;