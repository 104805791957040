export enum DataTableColumnTypesEnum {
  bool = 'bool',
  date = 'date',
  field = 'field',
  template = 'template',
  type = 'type',
  checkbox = 'checkbox',
}

export enum ColumnGroupEnum {
  additionalFields = 'Additional Fields',
  googleAdwords = 'Google Adwords',
  googleAnalytics = 'Universal Analytics',
  googleAnalytics4 = 'Google Analytics 4',
  googleSearchConsole = 'Google Search Console',
  price_monitor = 'Price monitor',
  phrase_monitor = 'Phrase monitor',
}
